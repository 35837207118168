{
  "name": "spb-landing",
  "version": "1.2.3",
  "scripts": {
    "watch": "ng test",
    "build": "ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.2",
    "@angular/common": "^19.2.2",
    "@angular/compiler": "^19.2.2",
    "@angular/core": "^19.2.2",
    "@angular/forms": "^19.2.2",
    "@angular/platform-browser": "^19.2.2",
    "@angular/platform-browser-dynamic": "^19.2.2",
    "@angular/router": "^19.2.2",
    "rxjs": "~7.8.2",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.3",
    "@angular-devkit/core": "^19.2.3",
    "@angular-devkit/schematics": "^19.2.3",
    "@angular/cdk": "^19.2.3",
    "@angular/cli": "~19.2.3",
    "@angular/compiler-cli": "^19.2.2",
    "@angular/material": "^19.2.3",
    "@sparbanken-syd/personnummer": "^3.0.1",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.3",
    "@types/jasmine": "~5.1.7",
    "@types/mocha": "^10.0.10",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "angular-eslint": "^19.2.1",
    "eslint": "^9.22.0",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.3",
    "stylelint": "^16.15.0",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "typescript": "~5.8.2",
    "typescript-eslint": "^8.26.1"
  }
}
